import { useFlags } from 'launchdarkly-react-client-sdk';
import DriversOld from './drivers-old';
import Drivers from './drivers';
import NextNprogress from 'nextjs-progressbar';

export default function CdlDrivers() {
  const { landingCdlDriversNewPage } = useFlags();

  if (landingCdlDriversNewPage === undefined) {
    // TODO: add loader
    return <NextNprogress color="#f0ff69" stopDelayMs={4000} />;
  }

  if (landingCdlDriversNewPage) {
    return (
      <>
        <Drivers />
      </>
    );
  }
  return (
    <>
      <DriversOld />
    </>
  );
}
